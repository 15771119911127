// 引入 vue 和 创建的 toast vue
import Vue from 'vue'
import Toast from './Index.vue'
 
// 创建 Toast Vue 子类
const ToastMsg = Vue.extend(Toast)
 
// 传入内容和 类型
Toast.install = (options, type, time) => {
	// 判断数据类型，并对应处理
	if (options === undefined || options === null) {
		options = {
			content: ''
		}
	} else if (typeof options === 'string' || typeof options === 'number') {
		options = {
			content: options
		}
		if (type !== undefined && options !== null) {
			options.type = type
		}
        if (time !== undefined && options !== null) {
			options.time = time
		}
	}
 
	// 创建Toast实例,传入 data 数据
	let instance = new ToastMsg({
		data: options
	}).$mount()
 
	// 添加到 html 页面
	document.body.appendChild(instance.$el)
 
	// 在修改数据之后立即使用这个方法，获取更新后的 DOM
	Vue.nextTick(() => {
		instance.visible = true
	})
}
 
export default Toast.install