<template>
  <div id="app">
    <div class="header" v-if="$route.meta.header">
      <div class="iconfont icon-back" @click="$router.go(-1)"></div>
      <div class="title">{{ $route.meta.title }}</div>
      <div></div>
    </div>
    <div class="header-seat" v-if="$route.meta.header"></div>
    <router-view/>
  </div>
</template>
<script>
  export default {
  }
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333333;
}
.header-seat {
  width: 375px;
  height: 49px;
}
.header {
  width: 375px;
  height: 49px;
  background: #0166C4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .icon-back {
    font-size: 16px;
    color: #ffffff;
    margin-left: 10px;
    position: relative;
    z-index: 1;
  }
  .title {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
