<template>
    <div :class="['main-text', fontClass]" v-html="htmlStr"></div>
</template>
<script>
export default {
    data() {
        return {
            fontClass: ''
        }
    },
    props: {
        htmlStr: {
            type: String,
            default: ''
        }
    },
    watch: {
        htmlStr(data) {
            this.handleFontClass(data)
        }
    },
    mounted() {
        this.handleFontClass(this.htmlStr)
    },
    methods: {
        handleFontClass(html) {
            const rowArr = html.split('<br/>')
            rowArr.forEach(item => {
                if(item.length > 16) {
                    this.fontClass = 'medium-font'
                }
                if(item.length > 21) {
                    this.fontClass = 'min-font'
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
    .main-text {
        font-size: 20px;
        font-weight: bold;
        color: #9C0606;
        text-align: center;
        padding: 16px;
        position: relative;
        &::after {
            content: "";
            width: 339px;
            height: 1px;
            background: #9C0606;
            display: block;
            margin-top: 16px;
        }
        &.min-font {
            font-size: 12px;
        }
        &.medium-font {
            font-size: 16px;
        }
    }
</style>