<template>
    <div class="pdf-download-view">
        <main-title :htmlStr="title"></main-title>
        <div class="pdf-list">
            <div class="item" v-for="item,i in fileList" :key="i" @click="downloadFile(item)">
                <div class="icon-pdf"></div>
                <div class="text">{{ item.FileName }}</div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Download } from '@/service/apis/common'
    import MainTitle from '@/components/MainTitle'
    export default {
        data() {
            return {
                title: '',
                fileList: []
            }
        },
        components: {
            MainTitle
        },
        mounted() {
            this.getFileList()
        },
        methods: {
            async getFileList() {
                const res = await Download({
                    Oid: localStorage.getItem('Oid')
                })
                this.fileList = res.Data.FileList
                this.title = res.Data.Title
            },
            downloadFile(data) {
                window.open(data.FilePath)
            }
        }
    }
</script>
<style lang="less" scoped>
    .pdf-download-view {
        min-height: 100vh;
        background: #F3F3F3;
        overflow: auto;
    }
    .pdf-list {
        margin-top: 20px;
        .item {
            width: 363px;
            height: 102px;
            background: #FFFFFF;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 38px;
            box-sizing: border-box;
            margin: 0 auto 10px;
            .icon-pdf {
                width: 38px;
                height: 42px;
                background: url(../assets/images/icon_pdf.png) no-repeat;
                background-size: 100%;
                flex-shrink: 0;
            }
            .text {
                font-size: 14px;
                color: #606060;
                text-align: left;
                margin-left: 33px;
            }
        }
    }
</style>