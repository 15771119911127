<template>
    <div class="picture-view">
        <div class="media-list">
            <div class="item" v-for="(item, index) in mediaObjList" :key="index">
                <img :src="
                    `${item.FilePath}${ isImageOrVideo(item.FilePath) == 1 ? '?x-oss-process=image/resize,m_lfit,h_400,w_400' : '?x-oss-process=video/snapshot,t_1000,m_fast' }`"
                    @click="previewMedia(index)"/>
            </div>
        </div>
        <van-image-preview v-model="isShowPreview" :images="mediaList" :startPosition="previewIndex">
            <template v-slot:cover>
                <p class="tips">长按若无法保存图片，请点击右上角，用浏览器打开</p>
                <!-- <a :href="mediaList[previewIndex]" download class="download-link">图片下载</a> -->
                <!-- <button type="button" @click="downloadMedia">下载文件</button> -->
            </template>
        </van-image-preview>
        <div class="bottom-buttons" v-if="userInfo.RoleType == 1">
            <button type="button" class="camera">
                <input type="file" @change="uploadFiles" multiple accept="image/*, video/*" />
                上传照片
            </button>
            <!-- <button type="button" class="search">寻找我</button> -->
        </div>
    </div>
</template>
<script>
import { AlbumLivePolicyToken, AlbumLive } from '@/service/apis/common';
import axios from 'axios'
export default {
    data() {
        return {
            Oid: localStorage.getItem('Oid'),
            mediaObjList: [],
            mediaList: [],
            userInfo: localStorage.getItem('LOGINDATA') ? JSON.parse(localStorage.getItem('LOGINDATA')) : {},
            isShowPreview: false,
            previewIndex: 0
        }
    },
    mounted() {
        this.getMediaList()
    },
    methods: {
        previewMedia(index) {
            this.previewIndex = index
            this.isShowPreview = true
        },
        async getMediaList() {
            const res = await AlbumLive({
                Oid: this.Oid || this.$route.query.oid
            });
            this.mediaObjList = res.Data
            this.mediaList = res.Data.map(item => {
                return item.FilePath
            })
            // this.$previewRefresh()
        },
        isImageOrVideo(filename) {
            // 获取文件后缀名
            const extension = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
            // 检查后缀名是否在图片或视频的数组中
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
            const videoExtensions = ['mp4', 'mov', 'm4v', 'avi', 'mpg', 'wmv', 'flv', '3gp'];
            let fileType = 0
            if(imageExtensions.includes(extension)) {
                fileType = 1
            }
            if(videoExtensions.includes(extension)) {
                fileType = 2
            }
            return fileType;
        },
        uploadFiles(event) {
            const files = event.target.files;
            if (!files || !files.length) {
                this.$toast(`上传失败： ${files.length}`)
                return
            };
            for(let i = 0; i < files.length; i++) {
                this.uploadToOSS(files[i])
            }
        },
        async uploadToOSS(file) {
            console.log(file)
            this.$loading.show('正在上传...')
            const { Data } = await AlbumLivePolicyToken({
                Oid: this.Oid
            })
            const formData = new FormData()
            formData.append('OSSAccessKeyId', Data.accessid);
            formData.append('policy', Data.policy);
            formData.append('Signature', Data.signature);
            formData.append('key', Data.dir + file.name);
            formData.append('callback', Data.callback);
            formData.append('success_action_status', '200');
            formData.append('file', file);
            // 发起直传请求
            axios.post(Data.host, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log('Upload success:', response);
                this.getMediaList();
                this.$loading.hidden()
            }).catch(error => {
                console.error('Upload failed:', error);
            });
        },
    },
};
</script>
<style lang="less" scoped>
.media-list {
    display: flex;
    flex-wrap: wrap;
    margin: 6px 2px 130px;
    .item {
        width: 179px;
        height: 179px;
        background: #f4f4f4;
        margin: 3px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.bottom-buttons {
    width: 100%;
    height: 118px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    button {
        width: 100px;
        height: 80px;
        color: #333333;
        background: #ffffff;
        box-shadow: 0px 0 20px 0px #e9e9e9;
        border-radius: 9px;
        margin: 0 15px;
    }
    .camera {
        position: relative;
        input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            opacity: 0;
        }
        &::before {
            content: '';
            width: 40px;
            height: 40px;
            background: url(../assets/images/camera.png) no-repeat;
            background-size: 96%;
            background-position: center;
            display: block;
            margin: 0 auto;
        }
    }
    .search {
        &::before {
            content: '';
            width: 40px;
            height: 40px;
            background: url(../assets/images/search.png) no-repeat;
            background-size: 80%;
            background-position: center;
            display: block;
            margin: 0 auto;
        }
    }
}
/deep/.van-image-preview__cover {
    width: 100%;
    top: auto;
    bottom: 50px;
    display: flex;
    justify-content: center;
    .tips {
        color: #ffffff;
    }
}
.download-link {
    padding: 6px 15px;
    font-size: 14px;
    color: #333333;
    display: block;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 auto;
}
</style>
