<template>
    <div class="signup-view">
        <div class="banner">
            <div class="banner-title-1">{{ `高级研修班（${ classData.Numbers }）` }}</div>
            <div class="banner-title-2">{{ classData.ClassTitle }}</div>
        </div>
        <div class="describe">
            <div class="item">
                <i class="icon icon-date"></i>
                <div class="text">
                    <p class="date">{{ classData.Time1 }}</p>
                    <p class="time">{{ classData.Time2 }}</p>
                </div>
            </div>
            <div class="item">
                <i class="icon icon-position"></i>
                <div class="text address">{{ classData.ClassAddr }}</div>
            </div>
            <div class="item">
                <i class="icon icon-price"></i>
                <div class="text">
                    <!-- <p class="price"></p> -->
                    <p class="price-info" v-html="classData.FeeStandards"></p>
                </div>
            </div>
        </div>
        <div class="form">
            <div class="form-item required">
                <div class="label">姓名</div>
                <div class="input">
                    <i class="iconfont icon-user"></i>
                    <input type="text" v-model="RealName" />
                </div>
            </div>
            <div class="form-item required">
                <div class="label">单位</div>
                <div class="input">
                    <i class="iconfont icon-unit"></i>
                    <input type="text" v-model="CompanyName" @focus="onFocusCompanyInput" @blur="onBlurCompanyInput" />
                </div>
                <div class="select-dropdown" v-if="isShowDropdown && CompanyName">
                    <div class="item" v-for="item, index in Members" :key="index" 
                        v-if="item.CompanyName.indexOf(CompanyName) !== -1"
                        @click.stop="onSelectCompany(item)">
                        {{ item.CompanyName }}
                    </div>
                </div>
            </div>
            <div class="form-item required">
                <div class="label">职务</div>
                <div class="input">
                    <i class="iconfont icon-duties"></i>
                    <input type="text" v-model="UserPost" />
                </div>
            </div>
            <div class="form-item required">
                <div class="label">手机</div>
                <div class="input">
                    <i class="iconfont icon-phone"></i>
                    <input type="text" v-model="UserTel" />
                </div>
            </div>
            <div class="form-item">
                <div class="label">微信号</div>
                <div class="input">
                    <input type="text" v-model="WeiXin" />
                </div>
            </div>
            <div class="form-item">
                <div class="label">发票信息</div>
                <div class="notes">
                    <p>需要填写单位名称，税号，地址，电话，开户行，账户</p>
                    <p>会员单位写“会员”即可</p>
                </div>
                <div class="input">
                    <textarea v-model="Invoices" rows="7"></textarea>
                </div>
            </div>
            <div class="form-item required">
                <div class="label">是否需要预留酒店住宿</div>
                <div class="notes">
                    <p>请注明房间数量、住宿日期及房型（标间或大床房、入住日期）</p>
                </div>
                <div class="input">
                    <select v-model="IsHotel">
                        <option value="0">否</option>
                        <option value="1">是</option>
                    </select>
                    <i class="iconfont icon-arrow-bottom right-icon"></i>
                </div>
            </div>
            <div class="form-item required hotel-child" v-if="IsHotel == 1">
                <div class="label">房型</div>
                <div class="input">
                    <select v-model="SelRoom">
                        <option value="大床">大床</option>
                        <option value="双床">双床</option>
                    </select>
                    <i class="iconfont icon-arrow-bottom right-icon"></i>
                </div>
            </div>
            <div class="form-item required hotel-child" v-if="IsHotel == 1">
                <div class="label">入住日期</div>
                <div class="input">
                    <input type="date" v-model="InTime" placeholder="选择日期" />
                    <i class="iconfont icon-arrow-bottom right-icon"></i>
                </div>
            </div>
            <div class="form-item required hotel-child" v-if="IsHotel == 1">
                <div class="label">离店日期</div>
                <div class="input">
                    <input type="date" v-model="OutTime" />
                    <i class="iconfont icon-arrow-bottom right-icon"></i>
                </div>
            </div>
            <button type="button" class="submit-button" @click="onSubmit" :disabled="isSubmitDisabled">提交</button>
        </div>
        <div class="tips">
            团体报名请下载
            <a :href="classData.DownLoadFile" class="link" target="_blank">报名表模板</a>
            ，填写后发给赛老师即可。</div>
        <div class="contact">
            <div class="title">联系方式</div>
            <div class="info">
                <p>联系人：赛老师</p>
                <p>联系电话：18612302708</p>
                <p>微信：18612302708</p>
            </div>
        </div>
        <div class="contact">
            <div class="title">欢迎关注“赛尼尔法务管理”微信公众号</div>
            <div class="info">
                <p>长按并识别二维码后即可关注</p>
            </div>
            <img class="qrcode" :src="require('../assets/images/C5nhVivr3nWUb62O78UZaM7e76uRJMV2.jpg')"/>
        </div>
        <div class="mask" v-if="isShowSuccessPopup">
            <div class="success-popup">
                <div class="icon-success"></div>
                <p>报名成功</p>
                <button type="button" class="close-button" @click="clearInit">继续报名</button>
            </div>
        </div>

    </div>
</template>
<script>
import {
    OpenClassData,
    OpenClassRegister
} from '../service/apis/common'
export default {
    name: 'SignUpView',
    data() {
        return {
            Oid: localStorage.getItem('Oid'),
            classData: {},
            Members: [],
            RealName: '',
            CompanyName: '',
            UserPost: '',
            UserTel: '',
            WeiXin: '',
            Invoices: '',
            IsHotel: '0',
            SelRoom: '大床',
            InTime: '',
            OutTime: '',
            isShowDropdown: false,
            selectedCompanyInfo: {},
            isSubmitDisabled: false,
            isShowSuccessPopup: false
        }
    },
    mounted() {
        this.getSignUpInfo()
    },
    methods: {
        clearInit() {
            Object.keys(this.$data).forEach((key) => {
                if (key !== 'ClassData' && key !== 'Members') {
                    this.$data[key] = this.$options.data()[key];
                }
            });
            this.getSignUpInfo()
        },
        /**
         * 获取报名信息
         */
        async getSignUpInfo() {
            const res = await OpenClassData({
                Oid: this.Oid
            })
            this.classData = res.Data.ClassData
            this.Members = res.Data.Members
        },
        onFocusCompanyInput() {
            this.isShowDropdown = true
        },
        onBlurCompanyInput() {
            setTimeout(() => {
                this.isShowDropdown = false
            }, 200)
        },
        onSelectCompany(data) {
            this.selectedCompanyInfo = data
            this.CompanyName = data.CompanyName
            this.Invoices = data.Invoices
            this.isShowDropdown = false
        },
        async onSubmit() {
            if(!this.RealName) {
                this.$toast('请填写姓名')
                return
            }
            if(!this.CompanyName) {
                this.$toast('请填写公司名称')
                return
            }
            if(!this.UserPost) {
                this.$toast('请填写职位')
                return
            }
            if(!this.UserTel) {
                this.$toast('请填写手机号')
                return
            }
            /* if(!this.WeiXin) {
                this.$toast('请填写微信号')
                return
            } */
            if(this.IsHotel == 1) {
                if(!this.SelRoom) {
                    this.$toast('请选择房型')
                    return
                }
                if(!this.InTime) {
                    this.$toast('请选择入住日期')
                    return
                }
                if(!this.OutTime) {
                    this.$toast('请选择离店日期')
                }
            }
            this.isSubmitDisabled = true
            const res = await OpenClassRegister(JSON.stringify({
                Oid: this.Oid,
                Mid: this.selectedCompanyInfo.Mid,
                RealName: this.RealName,
                CompanyName: this.CompanyName,
                UserPost: this.UserPost,
                UserTel: this.UserTel,
                WeiXin: this.WeiXin,
                Invoices: this.Invoices,
                IsHotel: this.IsHotel,
                SelRoom: this.SelRoom,
                InTime: this.InTime,
                OutTime: this.OutTime
            }), {
                dataType: 'application/json'
            })
            this.isSubmitDisabled = false
            this.isShowSuccessPopup = true
        }
    }
}
</script>
<style scoped>
    input[type="date"], select {
        background-color: transparent;
        padding: 0 .1333rem;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    .signup-view {
        text-align: left;
    }
    .banner {
        width: 375px;
        height: 183px;
        background: url(../assets/images/banner.png) no-repeat 0/100%;
        color: #ffffff;
        text-align: center;
        overflow: hidden;
    }
    .banner .banner-title-1 {
        font-size: 18px;
        margin-top: 86px;
    }
    .banner .banner-title-2 {
        font-size: 20px;
        margin-top: 5px;
    }
    .describe {
        background: #ededed;
        padding: 15px 20px;
    }
    .describe .item {
        display: flex;
        margin-bottom: 10px;
    }
    .describe .item .icon {
        width: 18px;
        height: 18px;
        margin-top: 2px;
        flex-shrink: 0;
    }
    .describe .item .icon-date {
        background: url(../assets/images/icon_date.png) no-repeat 0/100%;
    }
    .describe .item .icon-position {
        background: url(../assets/images/icon_position.png) no-repeat 0/100%;
    }
    .describe .item .icon-price {
        background: url(../assets/images/icon_price.png) no-repeat 0/100%;
    }
    .describe .item .text {
        font-size: 14px;
        text-align: left;
        margin-left: 6px;
    }
    .form {
        margin-top: 30px;
    }
    .form .form-item {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        margin-bottom: 15px;
        position: relative;
    }
    .form .form-item .label {
        font-size: 16px;
        color: #333333;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
    .form .form-item.required .label::after {
        content: "*";
        color: #da2824;
        margin-left: 5px;
    }
    .form .form-item .notes {
        font-size: 12px;
        color: #7C7C7C;
        margin-bottom: 5px;
    }
    .form .form-item .input {
        width: 100%;
        min-height: 40px;
        border: 1px solid #d3d3d3;
        display: flex;
        align-items: center;
        padding: 0 5px;
        box-sizing: border-box;
        position: relative;
    }
    .form .form-item .warn {
        border-color: #da2824;
    }
    .form .form-item .input:hover {
        border-color: #2D8FD9;
    }
    .form .form-item .input .iconfont {
        font-size: 18px;
        color: rgba(45,143,217,.6);
        margin-right: 5px;
    }
    .form .form-item .input .right-icon {
        position: absolute;
        right: 5px;
        z-index: 1;
        font-size: 14px;
        color: #666666;
    }
    .form .form-item .input input, .form .form-item .input select, .form .form-item .input textarea {
        width: 100%;
        height: 100%;
        border: none;
        font-size: 16px;
        color: #333333;
    }
    .form .form-item .input textarea {
        padding: 5px;
    }
    .form .submit-button {
        width: 345px;
        height: 38px;
        background: #669acc;
        font-size: 16px;
        color: #ffffff;
        border-radius: 3px;
        display: block;
        margin: 0 auto;
    }
    .select-dropdown {
        width: 335px;
        max-height: 300px;
        background-color: #ffffff;
        font-size: 14px;
        color: #333333;
        overflow: auto;
        box-shadow: 1px 1px 5px #eeeeee;
        position: absolute;
        z-index: 1;
        top: 100%;
        /* display: none; */
    }
    .select-dropdown .item {
        padding: 10px;
    }
    .contact {
        padding: 0 20px;
        margin-top: 30px;
    }
    .contact .title {
        font-size: 16px;
        padding: 5px 0;
        border-bottom: 1px solid #d3d3d3;
    }
    .contact .info {
        font-size: 14px;
        color: #7C7C7C;
        margin-top: 5px;
    }
    .qrcode {
        width: 100%;
    }
    .mask {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.5);
    }
    .mask .toast {
        padding: 10px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 6px;
        font-size: 14px;
        color: #ffffff;
        opacity: 0;
    }
    .mask .success-popup {
        width: 330px;
        min-height: 200px;
        background: #ffffff;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 1px 1px 10px #eeeeee;
    }
    .mask .success-popup .icon-success {
        width: 110px;
        height: 97px;
        background: url(../assets/images/icon_success.png) no-repeat 0/100%;
        display: block;
        margin: 20px auto;
    }
    .mask .success-popup p {
        font-size: 20px;
        text-align: center;
    }
    .mask .success-popup .close-button {
        width: 280px;
        height: 38px;
        background: #3c67af;
        font-size: 16px;
        color: #ffffff;
        border-radius: 3px;
        display: block;
        margin: 20px auto;
    }
    .tips {
        font-size: 14px;
        margin: 20px;
    }
</style>