<template>
    <div class="participate-history-view">
        <div class="banner">
            <div class="logo"></div>
            <div class="title">{{ partRecordInfo.ZhuTi }}</div>
            <div class="subtitle">{{ partRecordInfo.QiShu }}</div>
        </div>
        <div class="info-box">
            <p>{{ `${partRecordInfo.CompanyName}您好：` }}</p>
            <p v-html="partRecordInfo.CompanyList[0].CompanyName"  v-if="partRecordInfo.CompanyList && partRecordInfo.CompanyList.length"></p>
        </div>
        <div class="tab-view">
            <div class="tab-header">
                <div :class="['tab-header-item', { 'active': tabActive == 1 }]" @click="tabActive = 1">消耗明细</div>
                <div :class="['tab-header-item', { 'active': tabActive == 2 }]" @click="tabActive = 2">充值明细</div>
            </div>
            <div class="tab-box">
                <div :class="['tab-box-item', { 'active': tabActive == 1 }]">
                    <select v-model="searchOid" class="select-input" @change="onChangeSearch">
                        <option value="">选择公开课期数  全部</option>
                        <option v-for="item in partRecordInfo.OpenClassList" :value="item.Oid">{{ `${item.Numbers} ${item.Area}` }}</option>
                    </select>
                    <div class="consume-list list">
                        <div class="item" v-for="item in ConsumeDetailList">
                            <div class="left-box">
                                <div class="top-box">
                                    <div class="title">{{ item.ZhuTi }}</div>
                                    <div class="date">{{ item.Date }}</div>
                                </div>
                                <div class="bottom-box">
                                    <b>{{ item.Name }}</b>{{ `${item.UserPost} ${item.CompanyName}` }}
                                </div>
                            </div>
                            <div class="right-box">{{ item.Days }}</div>
                        </div>
                    </div>
                </div>
                <div :class="['tab-box-item', { 'active': tabActive == 2 }]">
                    <div class="recharge-list list">
                        <div class="item" v-for="item in partRecordInfo.RechargeList">
                            <div class="left-box">{{`${item.Date} ${item.Des}`}}</div>
                            <div class="right-box">{{ item.Days }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { PartRecord } from '@/service/apis/common'
    export default {
        data() {
            return {
                searchOid: '',
                userInfo: localStorage.getItem('LOGINDATA') ? JSON.parse(localStorage.getItem('LOGINDATA')) : {},
                partRecordInfo: {},
                tabActive: 1,
                ConsumeDetailList: []
            }
        },
        mounted() {
            this.getPartRecord()
        },
        methods: {
            /**
             * 获取记录信息
             */
            async getPartRecord() {
                const Oid = localStorage.getItem('Oid')
                const res = await PartRecord({
                    Oid: Oid,
                    Mid: this.userInfo.Mid// this.userInfo.Mid 16508640061120637
                })
                this.partRecordInfo = res.Data
                this.ConsumeDetailList = res.Data.ConsumeDetailList
            },
            onChangeSearch() {
                const searchOid = this.searchOid
                if(!searchOid) {
                    this.ConsumeDetailList = this.partRecordInfo.ConsumeDetailList
                    return
                }
                this.ConsumeDetailList = this.partRecordInfo.ConsumeDetailList.filter(item => {
                    if(item.Oid == searchOid) {
                        return item
                    }
                })
                this.$forceUpdate()
            }
        }
    }
</script>
<style lang="less" scoped>
    .banner {
        width: 100%;
        height: 233px;
        background: url(../assets/images/home_banner.png) no-repeat;
        background-size: 100% 100%;
        padding-top: 47px;
        box-sizing: border-box;
        overflow: hidden;
        margin-top: -47px;
        .logo {
            width: 182px;
            height: 35px;
            background: url(../assets/images/logo.png) no-repeat;
            background-size: 100%;
            margin: 22px auto 20px;
        }
        .title {
            font-size: 28px;
            font-weight: bold;
            color: #004B90;
        }
        .subtitle {
            font-size: 18px;
            font-weight: bold;
            color: #004B90;
            margin-top: 10px;
        }
    }
    .info-box {
        width: 351px;
        /* min-height: 82px; */
        background: #004A8F;
        border-radius: 8px;
        margin: 12px auto;
        padding: 9px 13px;
        box-sizing: border-box;
        text-align: left;
        color: #ffffff;
    }
    .tab-view {
        margin-top: 10px;
        .tab-header {
            display: flex;
            justify-content: space-around;
            .tab-header-item {
                width: 50%;
                height: 36px;
                position: relative;
                padding-bottom: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                color: #004A8F;
                &::after {
                    content: "";
                    width: 130px;
                    height: 3px;
                    background: #B2B2B2;
                    border-radius: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    margin-left: -65px;
                }
                &.active::after {
                    background: #C01616;
                }
            }
        }
        .tab-box {
            .tab-box-item {
                display: none;
                .select-input {
                    width: 275px;
                    height: 34px;
                    border-bottom: 1px solid #DDDDDD;
                    padding-left: 30px;
                    margin-top: 10px;
                }
                .list {
                    margin-top: 15px;
                    padding-bottom: 30px;
                    .item {
                        width: 351px;
                        /* height: 82px; */
                        background: #F7F7F7;
                        border-radius: 8px;
                        padding: 9px;
                        box-sizing: border-box;
                        margin: 7px auto;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .left-box {
                            width: 273px;
                            margin-right: 7px;
                            position: relative;
                            text-align: left;
                            /* margin: 0 auto; */
                            .top-box {
                                text-align: left;
                                border-bottom: 1px solid #DDDDDD;
                                padding-bottom: 8px;
                                .title {
                                    font-size: 12px;
                                }
                                .date {
                                    font-size: 10px;
                                }
                            }
                            .bottom-box {
                                font-size: 12px;
                                text-align: left;
                                padding-top: 5px;
                                b {
                                    font-size: 12px;
                                    margin-right: 5px;
                                }
                            }
                            &::before {
                                content: "";
                                width: 1px;
                                height: 100%;
                                background: #DDDDDD;
                                position: absolute;
                                right: -7px;
                                top: 0;
                            }
                        }
                        .right-box {
                            width: 57px;
                            /* height: 74px; */
                            /* border-left: 1px solid #DDDDDD; */
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                        }
                    }
                }
            }
            .active {
                display: block;
            }
        }
    }
</style>