<template>
    <div class="invitation">
        <div class="dialog">
            <button type="button" class="iconfont icon-close" @click="$emit('close')"></button>
            <div class="content">
                <p class="text">确认被邀请单位全称</p>
                <input type="text" class="input" v-model="companyName" />
            </div>
            <div class="buttons">
                <button class="confirm-button" @click="downloadInvitation">确认名称并下载邀请函</button>
            </div>
        </div>
    </div>
</template>
<script>
    import { Invitation } from '@/service/apis/common'
    export default {
        data() {
            return {
                companyName: ''
            }
        },
        methods: {
            async downloadInvitation() {
                if(!this.companyName) {
                    this.$toast('请输入公司名称')
                    return
                }
                const res = await Invitation({
                    Oid: localStorage.getItem('Oid'),
                    CompanyName: this.companyName
                }, {
                    dataType: 'application/json'
                })
                window.open(res.Data)
                this.$emit('close')
            }
        }
    }
</script>
<style lang="less" scoped>
    .invitation {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        .dialog {
            width: 342px;
            height: 217px;
            background: #FFFFFF;
            border-radius: 10px;
            position: relative;
            .icon-close {
                width: 26px;
                height: 26px;
                background: #AC0C0C;
                font-size: 14px;
                color: #FFFFFF;
                border-radius: 50%;
                position: absolute;
                right: -8px;
                top: -8px;
            }
            .content {
                width: 100%;
                height: 150px;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-wrap: wrap;
                text-align: center;
                .text {
                    width: 100%;
                    font-size: 18px;
                    color: #454545;
                }
                .input {
                    width: 252px;
                    height: 29px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #9A9A9A;
                    padding: 0 10px;
                    box-sizing: border-box;
                    margin-top: 16px;
                }
            }
            .buttons {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 22px;
                .confirm-button {
                    width: 245px;
                    height: 42px;
                    background: #0055A4;
                    border-radius: 15px 15px 15px 15px;
                    font-size: 18px;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>