import { post } from '../base'
import domains from '../domains'

/**
 * 登录
*/
export function Login(data, configs) {
    return post(`${domains.main}/Lesson/Login`, data, configs)
}
/**
 * 获取验证码
*/
export function GetMobileCode(data, configs) {
    return post(`${domains.main}/Lesson/GetMobileCode`, data, configs)
}
/**
 * 首页信息
*/
export function Index(data, configs) {
    return post(`${domains.main}/Lesson/Index`, data, configs)
}
/**
 * 议程
*/
export function YiCheng(data, configs) {
    return post(`${domains.main}/Lesson/YiCheng`, data, configs)
}
/**
 * 报名
*/
export function OpenClassData(data, configs) {
    return post(`${domains.main}/Lesson/OpenClassData`, data, configs)
}
/**
 * 报名提交
*/
export function OpenClassRegister(data, configs) {
    return post(`${domains.main}/Lesson/OpenClassRegister`, data, configs)
}
/**
 * 邀请函下载
*/
export function Invitation(data, configs) {
    return post(`${domains.main}/Lesson/Invitation`, data, configs)
}
/**
 * 特邀讲师
*/
export function Lecturer(data, configs) {
    return post(`${domains.main}/Lesson/Lecturer`, data, configs)
}
/**
 * 课程资料下载
*/
export function Download(data, configs) {
    return post(`${domains.main}/Lesson/Download`, data, configs)
}
/**
 * 签到详情
*/
export function SignOn(data, configs) {
    return post(`${domains.main}/Lesson/SignOn`, data, configs)
}
/**
 * 签到弹窗信息
*/
export function SignOnInfo(data, configs) {
    return post(`${domains.main}/Lesson/SignOnInfo`, data, configs)
}
/**
 * 签到
*/
export function SignOnSaveInfo(data, configs) {
    return post(`${domains.main}/Lesson/SignOnSaveInfo`, data, configs)
}
/**
 * 取消签到
*/
export function SignCancelSaveInfo(data, configs) {
    return post(`${domains.main}/Lesson/SignCancelSaveInfo`, data, configs)
}
/**
 * oss直传签名
*/
export function AlbumLivePolicyToken(data, configs) {
    return post(`${domains.main}/Lesson/AlbumLivePolicyToken`, data, configs)
}
/**
 * 图片直播列表
*/
export function AlbumLive(data, configs) {
    return post(`${domains.main}/Lesson/AlbumLive`, data, configs)
}
/**
 * 参会历史
*/
export function PartRecord(data, configs) {
    return post(`${domains.main}/Lesson/PartRecord`, data, configs)
}