<template>
    <div class="invitation">
        <div class="dialog">
            <button type="button" class="iconfont icon-close" @click="$emit('close')"></button>
            <div class="title">{{ signOnInfo.RealName }}</div>
            <div class="content">
                <p class="text">{{ `${ signOnInfo.CompanyName } ${ signOnInfo.UserPost }` }}</p>
                <div class="form">
                    <div class="form-item">
                        <div class="label">培训名额扣除单位：</div>
                        <select class="input" v-model="deductCompany" @change="onChangeDeductCompany">
                            <option v-for="item,i in signOnInfo.CompanyList" :key="i" 
                                :value="item">{{ item.CompanyName }}</option>
                        </select>
                    </div>
                    <div class="form-item">
                        <div class="label">缴费金额：</div>
                        <input type="text" class="input" v-model="deductionAmount" />
                    </div>
                    <div class="form-item">
                        <div class="label">备注：</div>
                        <textarea class="input textarea" v-model="remarks"></textarea>
                    </div>
                    <div class="form-item">
                        <div class="label">是否自动创建订单：</div>
                        <div class="radio-group">
                            <label>
                                <input type="radio" name="auto" v-model="isAutoCreation" value="1" />
                                <span>创建</span>
                            </label>
                            <label>
                                <input type="radio" name="auto" v-model="isAutoCreation" value="0" />
                                <span>不创建</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button class="confirm-button" @click="onSign">签到</button>
                <button class="cancel-button" @click="cancelSing">取消签到</button>
            </div>
        </div>
    </div>
</template>
<script>
    import { SignOnInfo, SignOnSaveInfo, SignCancelSaveInfo } from '@/service/apis/common'
    export default {
        data() {
            return {
                Oid: localStorage.getItem('Oid'),
                // 用户信息
                userInfo: JSON.parse(localStorage.getItem('LOGINDATA')),
                signOnInfo: {},
                // 扣除单位
                deductCompany: null,
                // 扣费金额
                deductionAmount: null,
                // 选中的单位id
                mid: null,
                // 备注
                remarks: '',
                isAutoCreation: 1
            }
        },
        props: {
            signInfo: null
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            async getInfo() {
                const res = await SignOnInfo({
                    Oid: this.Oid,
                    SignId: this.signInfo.SignId
                })
                this.signOnInfo = res.Data
            },
            onChangeDeductCompany() {
                const deductCompany = this.deductCompany
                this.deductionAmount = deductCompany.FeeReg
                this.mid = deductCompany.Mid
            },
            async onSign() {
                if(!this.deductCompany) {
                    this.$toast('请选择扣除单位')
                    return
                }
                const res = await SignOnSaveInfo({
                    Oid: this.Oid,
                    SignId: this.signInfo.SignId,
                    Mid: this.mid,
                    FeeReg: this.deductionAmount,
                    Remarks: this.remarks,
                    IsAutoOrder: this.isAutoCreation
                }, {
                    dataType: 'application/json'
                })
                this.$toast(res.Message)
                this.$emit('update')
            },
            async cancelSing() {
                const res = await SignCancelSaveInfo({
                    Oid: this.Oid,
                    SignId: this.signInfo.SignId
                }, {
                    dataType: 'application/json'
                })
                this.$toast(res.Message)
                this.$emit('update')
            }
        }
    }
</script>
<style lang="less" scoped>
    .invitation {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        .dialog {
            width: 342px;
            // height: 260px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 20px 0;
            position: relative;
            .icon-close {
                width: 26px;
                height: 26px;
                background: #AC0C0C;
                font-size: 14px;
                color: #FFFFFF;
                border-radius: 50%;
                position: absolute;
                right: -8px;
                top: -8px;
            }
            .title {
                font-size: 18px;
                color: #414141;
            }
            .content {
                width: 100%;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-wrap: wrap;
                text-align: center;
                margin-top: 10px;
                .text {
                    width: 100%;
                    font-size: 12px;
                    color: #454545;
                }
                .form {
                    margin-top: 18px;
                    .form-item {
                        // display: flex;
                        // align-items: center;
                        margin-bottom: 6px;
                        .label {
                            font-size: 14px;
                            color: #414141;
                            text-align: left;
                        }
                        .input {
                            width: 270px;
                            height: 29px;
                            background: #FFFFFF;
                            border-radius: 4px;
                            border: 1px solid #9A9A9A;
                            padding: 0 10px;
                            box-sizing: border-box;
                            margin-top: 5px;
                        }
                        .textarea {
                            height: 50px;
                            padding: 10px;
                        }
                        .radio-group {
                            display: flex;
                            align-items: center;
                            margin-top: 5px;
                            label {
                                display: flex;
                                align-items: center;
                                line-height: 30px;
                                margin-right: 30px;
                                input {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
                
            }
            .buttons {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 15px;
                // position: absolute;
                // left: 0;
                // bottom: 22px;
                .confirm-button {
                    width: 90px;
                    height: 36px;
                    background: #4EC87F;
                    border-radius: 15px;
                    font-size: 14px;
                    color: #FFFFFF;
                    margin-right: 18px;
                }
                .cancel-button {
                    width: 90px;
                    height: 36px;
                    background: #FFCA27;
                    border-radius: 15px;
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>