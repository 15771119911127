import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import AgendaView from '../views/AgendaView.vue'
import SignUpView from '../views/SignUpView.vue'
import InvitedLecturerView from '../views/InvitedLecturerView.vue'
import PdfDownloadView from '../views/PdfDownloadView.vue'
import SignInView from '../views/SignInView.vue'
import PictureView from '../views/PictureView.vue'
import ParticipateHistoryView from '../views/ParticipateHistoryView.vue'

// 解决跳转重复路由报错问题的代码
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
	return routerPush.call(this, location).catch(err => {})
};
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView,
        meta: {
            title: '首页',
            auth: true
        }
    },
    {
        path: '/Login',
        name: 'LoginView',
        component: LoginView,
        meta: {
            title: '登录',
            auth: true,
            header: true
        }
    },
    {
        path: '/Agenda',
        name: 'AgendaView',
        component: AgendaView,
        meta: {
            title: '议程',
            auth: true,
            header: true
        }
    },
    {
        path: '/SignUp',
        name: 'SignUpView',
        component: SignUpView,
        meta: {
            title: '报名',
            auth: true,
            header: true
        }
    },
    {
        path: '/InvitedLecturer',
        name: 'InvitedLecturerView',
        component: InvitedLecturerView,
        meta: {
            title: '特邀讲师',
            auth: true,
            header: true
        }
    },
    {
        path: '/PdfDownload',
        name: 'PdfDownloadView',
        component: PdfDownloadView,
        meta: {
            title: '资料下载',
            auth: true,
            header: true
        }
    },
    {
        path: '/SignIn',
        name: 'SignInView',
        component: SignInView,
        meta: {
            title: '签到',
            auth: true,
            header: true,
            requiredLogin: true
        }
    },
    {
        path: '/ParticipateHistory',
        name: 'ParticipateHistoryView',
        component: ParticipateHistoryView,
        meta: {
            title: '参加历史',
            auth: true,
            header: true,
            requiredLogin: true
        }
    },
    {
        path: '/Picture',
        name: 'PictureView',
        component: PictureView,
        meta: {
            title: '图片直播',
            auth: true,
            header: true
        }
    }
]
const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    const { meta, path, query } = to
    const userInfo = localStorage.getItem('LOGINDATA')
    if(query && query.oid) {
        localStorage.setItem('Oid', query.oid)
    }
    if(userInfo) {
        if (meta.auth !== false) {
            next()
        } else {
            next({
                path: '/'
            })
        }
    } else {
        if (path === '/Login' || !meta.requiredLogin) {
            next()
        } else {
            next({
                path: '/Login'
            })
        }
    }
    //每次执行前，先移除上次插入的代码
    document.getElementById('51_la') && document.getElementById('51_la').remove();
    var hm = document.createElement("script");
    hm.src = "//js.users.51.la/21867613.js";
    hm.id = "51_la";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s)
    window.scrollTo(0, 0)
    document.title = meta.title || ''
})

export default router
