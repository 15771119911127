<template>
	<!-- 过度动画 -->
	<transition name="fade">
		<div class="toast-container" v-if="visible">
			<!-- Toast 内容 -->
			<div class="toast" :class="type">
				<div class="content">
					<span>{{ content }}</span>
				</div>
			</div>
		</div>
	</transition>
</template>
 
<script>
	export default {
		name: 'toast-tip',
		data() {
			return {
				content: '',
				time: 2000,
				visible: false,
				//对应的动态显示class type四种类型：info, success, warning, error
				type: 'info'
			}
		},
		mounted() {
			this.close();
		},
		methods: {
			close() {
				setTimeout(() => {
					this.visible = false;
				}, this.time);
			}
		}
	}
</script>
 
<style lang="less" scoped>
	/* 动画效果 淡入淡出 */
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s ease;
	}
 
	.fade-enter,
	.fade-leave-active {
		opacity: 0;
	}
 
	.toast-container {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		// 横居中
		justify-content: center;
		// 竖居中
		align-items: center;
		z-index: 99999;
 
		.toast {
			/* width: 340px; */
			padding: 6px 15px;
			border-radius: 6px;
			font-size: 14px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// 不同类型下的Toast 的显示样式
			&.info {
				background: rgba(0, 0, 0, 0.5);
				color: #ffffff;
			}
 
			&.success {
				background: #f0f9eb;
				color: #67c23a;
			}
 
			&.warning {
				background: #fdf6ec;
				color: #e6a23c;
			}
 
			&.error {
				background: #fef0f0;
				color: #f56c6c;
			}
		}
	}
</style>