// 引入 vue 和 创建的 Loading vue
import Vue from 'vue'
import Loading from './Index.vue'
 
// 创建 Loading Vue 子类
const LoadingMsg = Vue.extend(Loading)
let instance
// 传入内容和 类型
Loading.install = (options, type) => {
	// 判断数据类型，并对应处理
	if (options === undefined || options === null) {
		options = {
			content: ''
		}
	} else if (typeof options === 'string' || typeof options === 'number') {
		options = {
			content: options
		}
		if (type !== undefined && options !== null) {
			options.type = type
		}
	}
 
	// 创建Loading实例,传入 data 数据
	instance = new LoadingMsg({
		data: options
	}).$mount()
 
	// 添加到 html 页面
	document.body.appendChild(instance.$el)
 
	// 在修改数据之后立即使用这个方法，获取更新后的 DOM
	Vue.nextTick(() => {
		instance.visible = true
	})
}
Loading.show = (options) => {
	Loading.install(options)
}
Loading.hidden = () => {
	instance.visible = false
}
 
export default Loading