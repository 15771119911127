<template>
    <div class="login-view">
        <div class="banner">
            <div class="logo"></div>
            <div class="title">{{ homeInfo.ZhuTi }}</div>
            <div class="subtitle">{{ homeInfo.QiShu }}</div>
        </div>
        <div class="login-form">
            <div class="form-item">
                <i class="iconfont icon-phone-2"></i>
                <input type="text" placeholder="输入手机号" v-model="phoneNumber" />
            </div>
            <div class="form-item">
                <i class="iconfont icon-password-2"></i>
                <input type="text" placeholder="验证码" v-model="verificationCode" />
                <button type="button" class="validate-button" 
                    :disabled="!phoneNumber || isStartCount" 
                    @click="onSendSms">
                        {{ isStartCount ? countdown : '获取验证码' }}
                    </button>
            </div>
            <button type="button" class="login-button" @click="onLogin">登录</button>
        </div>
    </div>
</template>
<script>
import { Login, GetMobileCode, Index } from '@/service/apis/common.js'
export default {
    data() {
        return {
            // 首页信息
            homeInfo: {},
            // 手机号
            phoneNumber: '',// 18510249709
            // 验证码
            verificationCode: '',// 196621  685982042449514496
            // 是否开始计时
            isStartCount: false,
            // 验证码倒计时
            countdown: 60,
            verifyCount: null
        }
    },
    mounted() {
        this.getHomeInfo()
    },
    methods: {
        /**
         * 获取首页信息
         */
        async getHomeInfo() {
            const Oid = localStorage.getItem('Oid')
            const res = await Index({
                Oid: Oid
            })
            this.homeInfo = res.Data
        },
        /**
         * 验证手机号码
         * @param str 手机号
         */
        verifyPhone(str) {
            const reg = /^1[3456789]\d{9}$/
            if (reg.test(str)) {
                return true
            } else {
                return false
            }
        },
        /**
         * 获取验证码
         */
        async onSendSms() {
            const phoneNumber = this.phoneNumber
            if(!this.verifyPhone(phoneNumber)) {
                this.$toast('手机号格式错误')
                return
            }
            const res = await GetMobileCode({
                mobile: phoneNumber
            })
            this.isStartCount = true
            this.verifyCount = setInterval(() => {
                if(this.countdown > 1) {
                    this.countdown--
                } else {
                    this.countdown = 60
                    this.isStartCount = false
                    clearInterval(this.verifyCount)
                }
            }, 1000)
        },
        /**
         * 登录
         */
        async onLogin() {
            const phoneNumber = this.phoneNumber
            const verificationCode = this.verificationCode
            if(!phoneNumber) {
                this.$toast('请输入手机号')
                return
            }
            if(!this.verifyPhone(phoneNumber)) {
                this.$toast('手机号格式错误')
                return
            }
            if(!verificationCode) {
                this.$toast('请输入验证码')
                return
            }
            const res = await Login({
                Mobile: phoneNumber,
                Code: verificationCode
            })
            localStorage.setItem('LOGINDATA', JSON.stringify(res.Data))
            this.$router.push({path: '/'})
        }
    }
}
</script>
<style lang="less" scoped>
    .banner {
        width: 100%;
        height: 233px;
        background: url(../assets/images/home_banner.png) no-repeat;
        background-size: 100% 100%;
        padding-top: 47px;
        box-sizing: border-box;
        overflow: hidden;
        margin-top: -47px;
        .logo {
            width: 182px;
            height: 35px;
            background: url(../assets/images/logo.png) no-repeat;
            background-size: 100%;
            margin: 22px auto 20px;
        }
        .title {
            font-size: 28px;
            font-weight: bold;
            color: #004B90;
        }
        .subtitle {
            font-size: 18px;
            font-weight: bold;
            color: #004B90;
            margin-top: 10px;
        }
    }
    .login-form {
        margin-top: 20px;
        .form-item {
            width: 275px;
            height: 56px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #DCDCDC;
            margin: 10px auto 0;
            .iconfont {
                flex-shrink: 0;
            }
            input {
                width: 100%;
                height: 100%;
                padding: 0 14px;
                box-sizing: border-box;
            }
            .validate-button {
                width: 96px;
                height: 24px;
                background: #004A8F;
                border-radius: 11px;
                font-size: 12px;
                color: #ffffff;
                flex-shrink: 0;
                &:disabled {
                    background: #A8B2BF;
                }
            }
        }
        .login-button {
            width: 288px;
            height: 40px;
            background: #004A8F;
            border-radius: 20px;
            font-size: 14px;
            color: #ffffff;
            margin-top: 33px;
        }
    }
</style>