import axios from 'axios'
import qs from 'qs'
import toast from '@/components/toast/index.js'
const ERR_OK = 1
const baseURL = '/'
let loading = null
axios.defaults.baseURL = baseURL
axios.defaults.headers.ApiToken = 'rfkccl4vrit88vymi7su8jnrq7ojtmshdq4v'

// get请求
export function get(url, params) {
    return axios
        .get(url, {
            params
        })
        .then((res) => {
            const serverData = res.data
            if (serverData.code === ERR_OK) {
                return serverData.result
            }
        })
        .catch((err) => {
            console.log(err)
        })
}
// post请求
/**
 * post请求
 * @param {String} url 请求url
 * @param {Object} params 携带参数
 * @param {Object} configs post方法配置项
 */
export function post(url, params, configs) {
    const LOGINDATA = localStorage.getItem('LOGINDATA')
    if(LOGINDATA) {
        const userInfo = JSON.parse(LOGINDATA)
        axios.defaults.headers.UToken = userInfo.UToken
    }
    configs = configs || {}
    if(configs.dataType) {
        axios.defaults.headers['Content-Type'] = configs.dataType
    }
    return axios
        .post(`${ url }?${ qs.stringify(params) }`, configs.file || configs.dataType ? params : qs.stringify(params), {
            timeout: 360000
        })
        .then((res) => {
            const serverData = res.data
            if(serverData.Tag === 3) {
                localStorage.removeItem('LOGINDATA')
                toast(serverData.Message)
                return
            }
            if (serverData.Tag === ERR_OK) {
                return serverData
            }
            if (serverData.Tag !== ERR_OK) {
                toast(serverData.Message)
                console.log('请求错误')
            }
        })
        .catch((err) => {
            console.log(err)
        })
}
