<template>
    <div class="sign-in-view">
        <main-title :htmlStr="title"></main-title>
        <div id="pie-chart"></div>
        <div class="personnel-list">
            <div v-for="item,i in signList" :key="i" 
                :class="['item', { 'checked': item.IsVerified == 1 }, { 'not-checked': item.IsVerified == 0 }]"
                @click="onUserSign(item)">
                <div class="name">{{ item.RealName }}</div>
                <div class="tags">
                    <p>{{ item.CompanyName }}</p>
                    <p>{{ item.UserPost }}</p>
                </div>
            </div>
        </div>
        <sign-in-dialog :signInfo="currentSignInfo" 
            @close="isShowSign = false"
            @update="updatePage"
            v-if="isShowSign"></sign-in-dialog>
    </div>
</template>
<script>
import * as echarts from 'echarts/core'
import { PieChart } from 'echarts/charts'
import { TooltipComponent } from 'echarts/components'
import { LabelLayout, UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import SignInDialog from '../components/SignInDialog.vue'
import { SignOn } from '@/service/apis/common'
import MainTitle from '@/components/MainTitle.vue'
// 注册必须的组件
echarts.use([
    TooltipComponent,
    PieChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer
])
export default {
    data() {
        return {
            Oid: localStorage.getItem('Oid'),
            // 用户信息
            userInfo: JSON.parse(localStorage.getItem('LOGINDATA')),
            signList: [],
            title: '',
            // 已签到人数
            signedNumber: 0,
            // 未签到人数
            notSignedNumber: 0,
            isShowSign: false,
            currentSignInfo: null
        }
    },
    components: {
        SignInDialog,
        MainTitle
    },
    mounted() {
        this.getSignUpInfo();
    },
    methods: {
        updatePage() {
            this.getSignUpInfo()
            this.isShowSign = false
        },
        async getSignUpInfo() {
            const res = await SignOn({
                Oid: this.Oid,
                UToken: this.userInfo.UToken
            })
            this.signList = res.Data.SignList
            this.title = res.Data.Title
            let signedNumber = 0
            let notSignedNumber = 0
            res.Data.SignList.forEach(item => {
                if(item.IsVerified == 1) {
                    signedNumber++
                } else {
                    notSignedNumber++
                }
            })
            this.setPieChart([{
                    value: signedNumber,
                    name: '已签到',
                    itemStyle: {
                        color: '#4792D9'
                    }
                },
                {
                    value: notSignedNumber,
                    name: '未签到',
                    itemStyle: {
                        color: '#FFCA27'
                    }
                }])
        },
        setPieChart(data) {
            var myChart = echarts.init(document.getElementById('pie-chart'));
            myChart.setOption({
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                    name: '',
                    type: 'pie',
                    radius: '65%',
                    data: data,
                    label: {
                        show: true, // 显示数值
                        position: 'outside', // 数值显示在扇区外侧
                        formatter: '{b}: {c}' // 自定义标签的回调函数
                    },
                    emphasis: {
                        itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                    }
                ]
                });
        },
        onUserSign(data) {
            this.currentSignInfo = data
            this.isShowSign = true
        }
    }
}
</script>
<style lang="less" scoped>
.sign-in-view {
    min-height: 100vh;
    background: #F3F3F3;
    overflow: auto;
}
#pie-chart {
    width: 375px;
    height: 290px;
    margin-top: -20px;
}
.personnel-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 7px 20px;
    .item {
        width: 164px;
        height: 75px;
        background: #FFFFFF;
        border-radius: 10px;
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        margin: 6px 8px;
        &::before {
            content: "";
            width: 18px;
            height: 18px;
            border-radius: 50%;
            position: absolute;
            left: 3px;
            top: 3px;
        }
        &.checked::before {
            background: #4EC87F;
        }
        &.not-checked::before {
            background: #FFCA27;
        }
        .name {
            font-size: 18px;
            color: #414141;
        }
        .tags {
            width: 100%;
            font-size: 10px;
            color: #414141;
            margin-top: 8px;
            p {
                font-size: 10px;
            }
        }
    }
}
</style>