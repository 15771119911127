<template>
    <div class="agenda-view">
        <div class="article-box">
            <main-title :htmlStr="agendaInfo.Title"></main-title>
            <div class="text" v-html="agendaInfo.Contents"></div>
        </div>
        <div class="card-box">
            <div class="title">议程</div>
            <div class="card flow-card">
                <div class="group" v-for="group, gi in agendaInfo.CourseList" :key="gi">
                    <div class="group-title">{{ group.Days }}</div>
                    <div class="item" v-for="item, i in group.Courses" :key="i">
                        <div class="date">{{ item.Time }}</div>
                        <div class="content">
                            <div class="text" v-html="item.Title"></div>
                            <!-- <div class="label">国资委合规专项课题负责人</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-box">
            <div class="title">地点</div>
            <div class="card">
                <div class="text-list">
                    <div class="row">
                        <div class="content">
                            培训地点：
                            <span v-html="agendaInfo.ClassAddr"></span>
                        </div>
                    </div>
                    <div class="row" v-if="agendaInfo.HotelAddr">
                        <div class="content">
                            住宿地点：
                            <span v-html="agendaInfo.HotelAddr"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-box" v-if="agendaInfo.SponsorList && agendaInfo.SponsorList.length">
            <div class="title">赞助单位</div>
            <div class="card">
                <div class="sponsor">
                    <img v-for="item, i in agendaInfo.SponsorList" :key="i" :src="item.SponsorLogo" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { YiCheng } from '@/service/apis/common'
import MainTitle from '@/components/MainTitle.vue'
export default {
    name: 'AgendaView',
    data() {
        return {
            Oid: localStorage.getItem('Oid'),
            // 页面信息
            agendaInfo: {},
            mainContent: '自2015年中央企业全面启动合规管理工作以来，企业合规管理取得了长足进展，在完成了企业合规管理的基础性工作以后，如何实现从形式合规到实质合规，保障企业合规高质量发展，成为摆在中国企业目前的现实问题。为此我们围绕企业合规新形势、四体合一、刑事合规、合规认证、有效性评价、等重点工作，聘请具有丰富实践经验的一线合规管理专家和大家共同研讨，具体议程如下。'
        }
    },
    components: {
        MainTitle
    },
    mounted() {
        this.getAgendaInfo()
    },
    methods: {
        /**
         * 获取议程信息
         */
        async getAgendaInfo() {
            const res = await YiCheng({
                Oid: this.Oid
            })
            this.agendaInfo = res.Data
        }
    }
}
</script>
<style lang="less" scoped>
    .agenda-view {
        min-height: 100vh;
        background: #F3F3F3;
        overflow: auto;
    }
    .article-box {
        margin-top: 20px;
        .title {
            font-size: 16px;
            font-weight: bold;
            color: #9C0606;
            text-align: center;
            padding: 16px;
            position: relative;
            &::after {
                content: "";
                width: 339px;
                height: 1px;
                background: #9C0606;
                display: block;
                margin-top: 16px;
            }
        }
        .text {
            font-size: 14px;
            color: #414141;
            padding: 6px 25px;
            text-align: left;
            text-indent: 2em;
        }
    }
    .card-box {
        margin-top: 30px;
        .title {
            min-width: 123px;
            // padding: 0 45px;
            background: #0055A4;
            border-radius: 16px;
            font-size: 16px;
            line-height: 32px;
            color: #ffffff;
            box-sizing: border-box;
            display: inline-block;
            margin: 0 auto;
        }
        .card {
            width: 363px;
            background: #FFFFFF;
            border-radius: 10px 10px 10px 10px;
            margin: 10px auto;
            .text-list {
                padding: 24px;
                .row {
                    display: flex;
                    color: #606060;
                    margin-bottom: 15px;
                    text-align: left;
                    white-space: pre-wrap;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .sponsor {
                padding: 26px 0;
                img {
                    max-height: 38px;
                    margin: 10px;
                }
            }
        }
        .flow-card {
            overflow: hidden;
            .group {
                margin: 25px 18px;
                .group-title {
                    font-size: 16px;
                    color: #0165C3;
                    margin-bottom: 20px;
                }
                .item {
                    display: flex;
                    padding-bottom: 14px;
                    position: relative;
                    .date {
                        width: 76px;
                        height: 100%;
                        font-size: 12px;
                        color: #606060;
                        flex-shrink: 0;
                        padding-right: 24px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        &::before {
                            content: "";
                            width: 7px;
                            height: 7px;
                            background: #C8DBFF;
                            border: 3px solid #E4EEFF;
                            border-radius: 50%;
                            position: absolute;
                            right: 0;
                            top: 1.5px;
                            z-index: 1;
                        }
                        &::after {
                            content: "";
                            width: 1px;
                            height: 100%;
                            background: #E4EEFF;
                            position: absolute;
                            right: 6px;
                            top: 2px;
                        }
                    }
                    &:last-child {
                        .date::after {
                            content: none;
                        }
                    }
                    .content {
                        width: 210px;
                        margin-left: 114px;
                        .text {
                            font-size: 14px;
                            color: #606060;
                            text-align: left;
                        }
                        .label {
                            width: 207px;
                            height: 22px;
                            background: #EBF2FF;
                            border-radius: 4px;
                            font-size: 10px;
                            color: #606060;
                            padding: 0 11px;
                            box-sizing: border-box;
                            text-align: left;
                            line-height: 22px;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }
</style>