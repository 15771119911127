<template>
    <div class="invited-lecturerView">
        <main-title :htmlStr="lecturerInfo.Title"></main-title>
        <div class="lecturer-list">
            <div class="card-item" v-for="item,i in lecturerInfo.LecturerList" :key="i">
                <div class="info">
                    <div class="avatar">
                        <img :src="item.Portrait" v-if="item.Portrait" />
                    </div>
                    <div class="introduce">
                        <div class="top-box">
                            <div :class="['name', { 'hidden-name': item.TechName === '报名后可见' }]">{{ item.TechName }}</div>
                            <div class="datetime">
                                <div class="date">{{ item.CourseTime }}</div>
                                <div class="time">{{ item.Time }}</div>
                            </div>
                        </div>
                        <div class="describe">{{ item.CourseTitle }}</div>
                    </div>
                </div>
                <div class="content" v-if="item.JieShao">
                    <div class="text" v-html="item.JieShao"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Lecturer } from '@/service/apis/common'
import MainTitle from '@/components/MainTitle'
export default {
    data() {
        return {
            lecturerInfo: {}
        }
    },
    components: {
        MainTitle
    },
    mounted() {
        this.getLecturerInfo()
    },
    methods: {
        async getLecturerInfo() {
            const res = await Lecturer({
                Oid: localStorage.getItem('Oid')
            })
            this.lecturerInfo = res.Data
        }
    }
}
</script>
<style lang="less" scoped>
    .invited-lecturerView {
        min-height: 100%;
        background: #F3F3F3;
        overflow: auto;
    }
    .lecturer-list {
        margin-top: 20px;
        .card-item {
            width: 363px;
            padding: 16px 13px;
            box-sizing: border-box;
            background: #FFFFFF;
            border-radius: 10px;
            margin: 0 auto 20px;
            .info {
                display: flex;
                .avatar {
                    width: 100px;
                    height: 100px;
                    background: #D5E4FF;
                    flex-shrink: 0;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .introduce {
                    margin-left: 29px;
                    .top-box {
                        display: flex;
                        align-items: center;
                        .name {
                            font-size: 22px;
                            color: #414141;
                        }
                        .hidden-name {
                            font-size: 14px;
                        }
                        .datetime {
                            text-align: left;
                            margin-left: 36px;
                            .date {
                                font-size: 14px;
                                color: #0165C3;
                            }
                            .time {
                                font-size: 12px;
                                color: #606060;
                            }
                        }
                    }
                    .describe {
                        color: #0055A4;
                        text-align: left;
                        margin-top: 15px;
                    }
                }
            }
            .content {
                font-size: 14px;
                color: #606060;
                text-align: left;
                margin: 19px 5px;
            }
        }
    }
</style>