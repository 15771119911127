<template>
  <div class="home-view">
    <div class="home-header">
        <template v-if="userInfo">
            <div class="title">欢迎：{{ `${ userInfo.UserName }（${ userInfo.UserPost }）` }}</div>
            <div class="out-login" @click="onOutLogin">退出</div>
        </template>
        <template v-else>
            <div class="title"></div>
            <div class="out-login" @click="$router.push('/login')">登录</div>
        </template>
        
    </div>
    <div class="banner">
        <div class="logo"></div>
        <div class="title">{{ homeInfo.ZhuTi }}</div>
        <div class="subtitle">{{ homeInfo.QiShu }}</div>
    </div>
    <div class="main">
        <main-title :htmlStr="homeInfo.Title"></main-title>
        <div class="main-info">
            <div class="item">
                <div class="icon icon-date"></div>
                <div class="text">{{ homeInfo.Time1 }}</div>
            </div>
            <div class="item">
                <div class="icon icon-position"></div>
                <div class="text">
                    培训地点：<span v-html="homeInfo.ClassAddr"></span>
                </div>
            </div>
            <div class="item">
                <div class="icon"></div>
                <div class="text">
                    住宿地点：<span v-html="homeInfo.HotelAddr"></span>
                </div>
            </div>
        </div>
        <div class="nav-grid">
            <div class="nav-item" 
                v-for="item,index in navList" :key="index"
                @click="onNav(item)"
                v-if="(!item.isAdmin || (userInfo && userInfo.RoleType == 1)) && item.isShow">{{ item.label }}</div>
        </div>
    </div>
    <invitation-dialog v-if="isShowInvitation" @close="isShowInvitation = false"></invitation-dialog>
  </div>
</template>

<script>
    import InvitationDialog from '../components/InvitationDialog.vue'
    import { Index } from '@/service/apis/common'
    import MainTitle from '@/components/MainTitle.vue'
    export default {
        name: 'HomeView',
        data() {
            return {
                // 首页信息
                homeInfo: {},
                // 导航列表
                navList: [
                    {
                        label: '课程安排',
                        path: '/Agenda',
                        isShow: true
                    },
                    {
                        label: '报名',
                        path: '/SignUp',
                        isShow: true
                    },
                    {
                        label: '邀请函',
                        path: '',
                        isShow: false,
                        dialogShowName: 'isShowInvitation'
                    },
                    {
                        label: '图片直播',
                        path: `/Picture?oid=${localStorage.getItem('Oid') || this.$route.query.oid}`,
                        isShow: true
                    },
                    {
                        label: '本期特邀讲师',
                        path: '/InvitedLecturer',
                        isShow: true
                    },
                    {
                        label: '课程资料下载',
                        path: '/PdfDownload',
                        isShow: true
                    },
                    {
                        label: '签到管理',
                        path: '/SignIn',
                        isShow: true,
                        isAdmin: true
                    },
                    {
                        label: '2024会员政策',
                        path: '',
                        isShow: true
                    },
                    {
                        label: '参加历史',
                        path: '/ParticipateHistory',
                        isShow: true
                    },
                    {
                        label: '个人中心',
                        path: '/Login',
                        isShow: true
                    }
                ],
                // 邀请函弹窗
                isShowInvitation: false,
                // 用户信息
                userInfo: JSON.parse(localStorage.getItem('LOGINDATA'))
            }
        },
        components: {
            InvitationDialog,
            MainTitle
        },
        mounted() {
            // localStorage.setItem('Oid', this.$route.query.Oid)
            this.getHomeInfo()
        },
        methods: {
            /**
             * 获取首页信息
             */
            async getHomeInfo() {
                const Oid = localStorage.getItem('Oid')
                const res = await Index({
                    Oid: Oid
                })
                this.homeInfo = res.Data
            },
            /**
             * 导航跳转
             */
            onNav(option) {
                if(option.path) {
                    this.$router.push({
                        path: option.path
                    })
                } else {
                    this[option.dialogShowName] = true
                    // option.isShowDialog = true
                }
            },
            /**
             * 退出登录
             */
            onOutLogin() {
                localStorage.removeItem('LOGINDATA')
                this.$router.push({
                    path: '/Login'
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .home-header {
        width: 100%;
        height: 47px;
        background: rgba(255, 255, 255, 0.86);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 13px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
    }
    .banner {
        width: 100%;
        height: 233px;
        background: url(../assets/images/home_banner.png) no-repeat;
        background-size: 100% 100%;
        padding-top: 47px;
        box-sizing: border-box;
        overflow: hidden;
        .logo {
            width: 182px;
            height: 35px;
            background: url(../assets/images/logo.png) no-repeat;
            background-size: 100%;
            margin: 22px auto 20px;
        }
        .title {
            font-size: 28px;
            font-weight: bold;
            color: #004B90;
        }
        .subtitle {
            font-size: 18px;
            font-weight: bold;
            color: #004B90;
            margin-top: 10px;
        }
    }
    .main {
        .main-info {
            padding: 0 30px;
            .item {
                display: flex;
                margin-bottom: 16px;
                .icon {
                    width: 24px;
                    height: 24px;
                    flex-shrink: 0;
                }
                .icon-date {
                    background: url(../assets/images/icon_date.png) no-repeat;
                    background-size: 100%;
                }
                .icon-position {
                    background: url(../assets/images/icon_position.png) no-repeat;
                    background-size: 100%;
                }
                .text {
                    font-size: 16px;
                    margin-left: 13px;
                    text-align: left;
                    white-space: pre-wrap;
                }
            }
        }
        .nav-grid {
            display: flex;
            flex-wrap: wrap;
            padding: 0 18px;
            .nav-item {
                width: 149px;
                height: 70px;
                background: url(../assets/images/nav_item_bg.png) no-repeat;
                background-size: 100%;
                font-size: 14px;
                font-weight: bold;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px;
            }
        }
    }
</style>
