<template>
    <div class="loading-view" v-if="visible">
        <div class="loading">
            <span v-for="i in 6" :key="i"></span>
        </div>
        <div class="text">{{ content }}</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            visible: false,
            content: ''
        };
    },
    mounted() {
    },
    methods: {
        close() {
            this.visible = false;
        }
    },
};
</script>
<style lang="less" scoped>
.loading-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    .loading {
        position: relative;
        width: 50px;
        height: 50px;
        margin: 10px;
    }
    .text {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        color: #ffffff;
    }
}

/* 6个正方形 */
.loading span {
    height: 100%;
    width: 100%;
    position: absolute;
    animation: move 3.5s linear infinite;
}

@keyframes move {
    74% {
        transform: rotate(600deg);
    }

    79% {
        transform: rotate(720deg);
        opacity: 1;
    }

    80% {
        transform: rotate(720deg);
        opacity: 0;
    }

    100% {
        transform: rotate(810deg);
        opacity: 0;
    }
}

.loading span:nth-child(2) {
    animation-delay: 0.1s;
}

.loading span:nth-child(3) {
    animation-delay: 0.2s;
}

.loading span:nth-child(4) {
    animation-delay: 0.3s;
}

.loading span:nth-child(5) {
    animation-delay: 0.4s;
}

.loading span:nth-child(6) {
    animation-delay: 0.5s;
}

.loading span::before {
    content: '';
    position: absolute;
    height: 6px;
    width: 6px;
    background-color: #ffffff;
    border-radius: 50%;
    bottom: 0px;
    left: calc(50% - 5px);
}
</style>